.footer {
  padding: 38px 0;
  position: relative;
  @include media("<=810px") {
    overflow: visible;
    padding: 30px 0;
  }
  @include media("<=430px") {
    
  }
  
}

.footer__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  border-bottom: 1px #FDFDFD solid;
  @include media("<=650px") {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.copyright {
  padding-top: 20px;
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
  font-weight: 400;
  @include media("<=810px") {
    font-size: 12px;
  }
}