.about {
  overflow-x: clip;
  position: relative;
  padding-bottom: 100px;
  @include media("<=1024px") {
  }
  @include media("<=810px") {
    padding-bottom: 80px;
  }
  @include media("<=430px") {
    
  }
  
}

.about__inner {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  border-radius: 30px;
    background: #E2E1F8;
    padding: 40px;
    color: #000;
  @include media("<=810px") {
    max-width: 560px;
      margin: 0 auto;
      padding: 40px 20px;
  }
  @include media("<=500px") {
    padding: 20px 15px;
  }
}

.about__accounts {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  @include media("<=810px") {
    row-gap: 20px;
    flex-direction: column;
  }
}

.about__item {
  border-radius: 20px;
    background: #FDFDFD;
    padding: 20px;
    flex: 0 1 49%;
}

.about__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  @include media("<=430px") {
    font-size: 20px;
  }
}

.about__text {
  font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    @include media("<=430px") {
      font-size: 14px;
    }
}

.about__list {
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  column-gap: 60px;
  @include media("<=810px") {
    flex-direction: column;
    row-gap: 20px;
  }
}

.about__column {
  max-width: 417px;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px;
}

.about__step {
  display: flex;
  column-gap: 14px;
  align-items: center;
}

.about__num {
  border-radius: 50%;
  display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 28px;
    width: 28px;
    height: 28px;
    background-color: #4E49BF;
    color: #FDFDFD;
    flex: 0 1 100%;
}






