body {
  margin: 0;
font-family: "Montserrat", sans-serif;
  color: #FDFDFD;
  background-color: #000;
  position: relative;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

.container {
  width: 100%;
  max-width: 1318px;
  padding: 0 15px;
  margin: 0 auto;
}

.section__title {
  color: #fdfdfd;
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  column-gap: 10px;
  max-width: 1179px;
  @include media("<=1080px") {
    font-size: 50px;
  }
  @include media("<=810px") {
    font-size: 60px;
    max-width: 560px;
    margin: 0 auto;
  }
  @include media("<=430px") {
    font-size: 40px;
  } 
  @include media("<=350px") {
   
  }
  &-black {
    color: #000;
  }
}

.tab__wrapper {
  width: 100%;
  height: 100%;
}

.tab {
  
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media("<=1080px") {
   
  }

  @include media("<=430px") {
  
  }


}

.tablinks {
  cursor: pointer;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 1px rgba(0, 0, 0, 0.20) solid;
  transition: all 0.3s linear;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.40);
  flex: 0 1 50%;
  display: flex;
    align-items: center;
    justify-content: center;
  @include media("<=1080px") {
    
  }

  @include media("<=810px") {
   
  }

  &.active {
    color: #000;
    border-bottom: 1px #000 solid;
  }
}

.tab__content {
  display: none;
  width: 100%;
  height: 100%;
  animation: slide-down 0.3s ease-out;

  @include media("<=1080px") {
    
  }

  @include media("<=810px") {
    
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(10%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.tab__content:first-of-type {
  display: flex;
}

.page__title {
  font-size: 64px;
  font-weight: 800;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.page__content {
  padding: 40px 0 100px;
}

.page__inner {
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.page__text {
  line-height: 1.2;
  font-size: 15px;
  font-weight: 400;
  p {
    padding-top: 18px;
  }
}


