.header {
  width: 100%;
 
  @include media("<=810px") {

  }
  @include media("<=430px") {
  
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 50;
  position: relative;
  padding: 30px 0 20px;
  border-bottom: 1px #FDFDFD solid;
  @include media("<=650px") {
    flex-wrap: wrap;
    row-gap: 20px;
    padding-bottom: 15px;
  }
    
}

.header__nav {
  column-gap: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include media("<=650px") {
    justify-content: flex-start;
    column-gap: 50px;
  }
    @include media("<=450px") {
      justify-content: space-between;
      column-gap: 10px;
    }
}

.header__link {
  position: relative;
  font-size: 15px;
  text-decoration: none;
  color: #FDFDFD;
  transition: all 0.3s linear;
  @include media("<=450px") {
    
  }
  &:hover {
    

  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.08em;
  background: #fdfdfd;
  bottom: -6px;
  left: 0;
  border-radius: 3px;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.header__logo {
  color: #FDFDFD;
  text-decoration: none;
  font-size: 18px;
  width: 100%;
}
