.info {
    padding: 0 0 100px;
    @include media("<=810px") {
  
    }
    
}

.info__inner {
    padding-top: 30px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    color: #000;
    @include media("<=810px") {
       flex-wrap: wrap;
       justify-content: center;
       gap: 20px;
    }
}

.info__item {
       flex: 0 1 24%;
    max-width: 307px;
    min-height: 300px;
    border-radius: 24px;
        background: #FDFDFD;
        padding: 20px;
        &:nth-of-type(2n) {
            background: #E2E1F8;
        }
        @include media("<=810px") {
            flex: 0 1 48%;
        }
        @include media("<=540px") {
            flex: 0 1 100%;
            max-width: 350px;
            min-height: auto;
            padding: 30px 20px;
        }
}

.info__title {
    font-size: 22px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 20px;
        @include media("<=810px") {
            
            
        }
}


.info__text {
        font-size: 16px;
        font-weight: 400;
        display: flex;
            flex-direction: column;
            row-gap: 20px;
            @include media("<=1080px") {
                row-gap: 14px;
                font-size: 14px;
            }
        @include media("<=810px") {
          font-size: 16px;
        }
            
        

}