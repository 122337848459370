.features {
  padding-bottom: 100px;
@include media("<=810px") {
    padding-bottom: 80px;
}
}

.features__inner {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    @include media("<=810px") {
        flex-direction: column;
        align-items: center;
    }
}

.features__item {
    border-radius: 24px;
        background: #FDFDFD;
    //    height: 126px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    flex: 0 1 32%;
    font-size: 18px;
    color: #000;
    @include media("<=1080px") {
       flex: 0 1 31%;
       font-size: 16px;
       padding: 30px 20px;
    }
    &-big {
        flex: 0 1 49%;
        @include media("<=1080px") {
            flex: 0 1 48%;
        }
    }
        @include media("<=810px") {
            flex: 0 1 100%;
            max-width: 560px;
        }
        @include media("<=430px") {
            column-gap: 15px;
        }
}