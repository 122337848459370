.partners {
    padding-bottom: 100px;
}

.partners__inner {
    padding: 40px;
    border-radius: 30px;
        background: #FDFDFD;
    @include media("<=810px") {
      max-width: 560px;
      margin: 0 auto;
      padding: 40px 20px;
    }
}

.partners__services {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    @include media("<=810px") {
        justify-content: space-around;
        gap: 30px;
        flex-wrap: wrap;
    }
}

.partners__item {
    max-width: 158px;
    img {
        width: 100%;
        display: block;
    }
    &:last-of-type {
        max-width: 215px;
    }
}