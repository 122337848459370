

.form {
    border-radius: 30px;
        background: #FDFDFD;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    height: 100%;
    padding: 30px 20px 20px;
@include media("<=810px") {
  
}
}

.form__input {
    width: 100%;
    input {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.40);
        border-radius: 30px;
            border: 1px rgba(0, 0, 0, 0.12) solid;
            background: #FDFDFD;
        padding: 16px 20px;
    @include media("<=430px") {
        padding: 20px 15px;
    }
    }
   select {
    position: relative;
    font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 14px;
        color: #000;
        border-radius: 30px;
        border: 1px rgba(0, 0, 0, 0.12) solid;
        background: #FDFDFD;
        padding: 16px 20px;
        -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
    background-image: url("../images/arrow.png");
        background-repeat: no-repeat;
        background-position: right 20px top 50%;
        background-size: 12px 12px;
   }
}

.form__checkbox {
    color: #000;
    width: 100%;
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    font-size: 13px;
    
}

.form__button {
    margin-top: 10px;
    font-family: "Montserrat", sans-serif;
    border-radius: 30px;
        background: #4E49BF;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        padding: 16px;
        width: 100%;
        border: none;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
            background-color: #9593c4;
        }
}

.form__link {
    color: #000;
    font-size: 13px;
    text-decoration: none;
}

