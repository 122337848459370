.hero {
  position: relative;
  padding: 30px 0 100px;

  @include media("<=1080px") {

  }

  @include media("<=810px") {
    padding-bottom: 80px;
  }

  @include media("<=430px") {
    
    
  }
}

.hero__inner {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  column-gap: 20px;

  @include media("<=1280px") {

    margin: 0 auto;
  }

  @include media("<=1080px") {}

  @include media("<=810px") {
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

  @include media("<=430px") {}
}

.hero__content {
  position: relative;
  z-index: 3;
  flex: 0 1 100%;
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  align-items: center;

  @include media("<=1280px") {}

  @include media("<=1080px") {}

  @include media("<=810px") {
    max-width: 560px;
  }

  @include media("<=430px") {}
}

.hero__title {
  font-size: 70px;
  font-weight: 800;
  color: #000;
  position: relative;
  z-index: 3;
  text-transform: uppercase;
  margin-bottom: 20px;
  @include media("<=1280px") {
    font-size: 60px;
    
  }

  @include media("<=1080px") {
    font-size: 50px;

  }

  @include media("<=810px") {
    font-size: 60px;
    
  }

  @include media("<=430px") {
    font-size: 44px;

  }

  @include media("<=350px") {
    font-size: 40px;
  }
}

.hero__subtitle {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  
}

.hero__text {
border-radius: 30px;
  background: #E2E1F8;
  padding: 40px;
  @include media("<=1280px") {
    padding: 30px;
  }
  @include media("<=430px") {
    padding: 20px;
  }
}

.button {
  font-family: "Montserrat", sans-serif;
    border-radius: 30px;
    background: #4E49BF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    padding: 16px;
    width: 100%;
    border: none;
    font-size: 14px;
    font-weight: 800;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;
  
    &:hover {
      background-color: #9593c4;
    }

  @include media("<=810px") {
    max-width: 560px;
    margin: 0 auto;
  }
 
  &:hover {
   
  }
}

.hero__image {
  flex: 0 1 100%;
  width: 100%;
  position: relative;
  max-width: 100%;
  aspect-ratio: 780 / 215;
  border-radius: 30px;
    background: #E2E1F8;
    overflow: hidden;
  @include media("<=810px") {
    margin-bottom: 30px;
    aspect-ratio: 33 / 18;
  }

  @include media("<=430px") {
    
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: fit-content;
      height: 100%;
    @include media("<=1280px") {
      
    }
  }
}

.hero__form {
  max-width: 488px;
  width: 100%;
  flex: 0 1 100%;
  border-radius: 30px;
  overflow: hidden;
  background: #FDFDFD;
  min-height: 631px;
  @include media("<=1280px") {
    max-width: 420px;
  }
     @include media("<=810px") {
       max-width: 560px;
     }
}